import { SvgIcon } from '@material-ui/core';
import React from 'react';

const DownloadSvgCompontIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1162 10.5955C10.1146 10.5746 10.1139 10.5535 10.1139 10.5322V3.86556C10.1139 3.40556 10.4864 3.03223 10.9472 3.03223C11.408 3.03223 11.7805 3.40556 11.7805 3.86556V10.5321L13.7805 9.03223C14.1489 8.75473 14.6714 8.83056 14.9472 9.19889C15.2239 9.56723 15.1489 10.0897 14.7805 10.3656L11.4472 12.8656C11.2997 12.9764 11.123 13.0322 10.9472 13.0322C10.7797 13.0322 10.6122 12.9814 10.468 12.8806L7.13468 10.5356C6.75802 10.2706 6.66718 9.75056 6.93218 9.37473C7.19718 8.99806 7.71635 8.90723 8.09302 9.17223L10.1162 10.5955ZM5.94718 14.6989V15.5322H15.9472V14.6989C15.9472 14.2406 16.3222 13.8656 16.7805 13.8656C17.2389 13.8656 17.6139 14.2406 17.6139 14.6989V16.3656C17.6139 16.8239 17.2389 17.1989 16.7805 17.1989H5.11385C4.65552 17.1989 4.28052 16.8239 4.28052 16.3656V14.6989C4.28052 14.2406 4.65552 13.8656 5.11385 13.8656C5.57218 13.8656 5.94718 14.2406 5.94718 14.6989Z"
          fill="white"
        />
        <mask
          id="mask0_3839_855"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="3"
          width="14"
          height="15"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.1162 10.5955C10.1146 10.5746 10.1139 10.5535 10.1139 10.5322V3.86556C10.1139 3.40556 10.4864 3.03223 10.9472 3.03223C11.408 3.03223 11.7805 3.40556 11.7805 3.86556V10.5321L13.7805 9.03223C14.1489 8.75473 14.6714 8.83056 14.9472 9.19889C15.2239 9.56723 15.1489 10.0897 14.7805 10.3656L11.4472 12.8656C11.2997 12.9764 11.123 13.0322 10.9472 13.0322C10.7797 13.0322 10.6122 12.9814 10.468 12.8806L7.13468 10.5356C6.75802 10.2706 6.66718 9.75056 6.93218 9.37473C7.19718 8.99806 7.71635 8.90723 8.09302 9.17223L10.1162 10.5955ZM5.94718 14.6989V15.5322H15.9472V14.6989C15.9472 14.2406 16.3222 13.8656 16.7805 13.8656C17.2389 13.8656 17.6139 14.2406 17.6139 14.6989V16.3656C17.6139 16.8239 17.2389 17.1989 16.7805 17.1989H5.11385C4.65552 17.1989 4.28052 16.8239 4.28052 16.3656V14.6989C4.28052 14.2406 4.65552 13.8656 5.11385 13.8656C5.57218 13.8656 5.94718 14.2406 5.94718 14.6989Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3839_855)"></g>
      </svg>
    </SvgIcon>
  );
};

export default DownloadSvgCompontIcon;
