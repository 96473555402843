import React from 'react';
import { Box, Card, Typography, Divider } from '@material-ui/core';

const TextChart = ({ data }: any) => {
  let { type, header, body } = data;
  let { size = 'large' } = header;
  let backgroundColor = type === 'custom' ? '#2e3a59' : 'white';
  let titleTextColor = type === 'custom' ? 'white' : '#0095ff';
  let textColor = type === 'custom' ? 'white' : 'black';
  let borderColor = type === 'custom' ? '#2e3a59' : '#0095ff';
  let textSize = (size: any) => (size === 'large' ? 24 : 16);
  let fontWeight = (size: any) => (size === 'large' ? 'bold' : 'normal');

  return (
    <Card
      variant={'outlined'}
      style={{ borderColor: borderColor, borderWidth: 2 }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 16,
          backgroundColor: backgroundColor,
        }}
      >
        <Typography style={{ color: titleTextColor, textAlign: 'left' }}>
          {header.title}
        </Typography>
        <Typography
          style={{
            color: textColor,
            fontSize: textSize(size),
            fontWeight: fontWeight(size),
          }}
        >
          {header.value}
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: titleTextColor }} />
      <>
        {body &&
          body.map((item: any, index: number) => {
            let { size = 'large' } = item;
            return (
              <>
                <Box
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 16,
                    backgroundColor: backgroundColor,
                  }}
                >
                  <Typography style={{ color: titleTextColor }}>
                    {item.title}
                  </Typography>
                  <Typography
                    style={{
                      color: textColor,
                      fontSize: textSize(size),
                      fontWeight: fontWeight(size),
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
                {index != body.length - 1 && (
                  <Divider style={{ backgroundColor: titleTextColor }} />
                )}
              </>
            );
          })}
      </>
    </Card>
  );
};

export default TextChart;
