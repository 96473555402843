import React, { memo, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Header, NavigationTopBar } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import MaterialTable, { Icons } from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { promoListApi } from 'src/services/promotions';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  loader: {
    height: 'auto',
    overflow: 'auto',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  body: {
    flex: 1,
    overflow: 'auto',
    paddingBottom: 100,
    paddingTop: 20,
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    zIndex: 999,
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
  },
  detailPanelIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
const tableIcons: Icons = {
  DetailPanel: React.forwardRef((props, ref) => {
    return <></>;
  }),
};
const Promotions = () => {
  const classes = useStyles();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const { pageNumber, pageSize = 10 } = useSelector(
    (state: any) => state.orders,
  );
  const { cashReport: page = 1 } = pageNumber;
  const inputFile = useRef(null);
  const [loader, setLoader] = useState(false);
  const [uploadData, setUploadData] = useState('');
  const [cleanUpload, setCleanUpload] = useState<boolean>(false);
  const [promoData, setPromoData] = React.useState([]);
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        cashReport: value,
      },
    });
  };

  const appendCurrency = (value: any) => {
    let number = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(value);
    return number;
  };

  const fetchPromoData = async (download?) => {
    setLoader(true);
    let params: any = {
      v1: 'easy_store_2',
      store_id: active_stores[0].store_id,
      page,
      token: cookies.app_token,
      download: typeof download == 'boolean' ? true : false,
    };
    return promoListApi
      .get(params)
      .then((res: any) => {
        setLoader(false);
        if (res.data.file_url) {
          window.open(res.data.file_url, '_self');
        }
        return res.data || {};
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const { isLoading: isPromoLoading, data: promosData } = useQuery(
    ['PromotionData', page, active_stores, pageSize],
    fetchPromoData,
    {
      refetchOnWindowFocus: false,
    },
  );
  const { isLoading: loading, mutate: promoMutate } = useMutation(
    ['PromotionData', page, active_stores, pageSize],
    fetchPromoData,
    {
      onSuccess: (promosData: any) => {
        setPromoData(promosData);
        queryClient.invalidateQueries({ queryKey: ['results'] });
        queryClient.refetchQueries({ queryKey: ['results'] });
      },
    },
  );

  useEffect(() => {
    setPromoData(promosData);
  }, [promosData]);

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };
  const uploadPromoData = async (file?) => {
    setLoader(true);
    let params: any = {
      v1: 'easy_store',
      store_id: active_stores[0].store_id,
      page,
      token: cookies.app_token,
    };
    return promoListApi
      .file(params, {
        store_id: active_stores[0].store_id,
        file: file,
        clean: cleanUpload ? 'True' : 'False',
      })
      .then((res: any) => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: res?.data?.data?.status_msg,
          severity: res?.data?.status === 200 ? 'success' : 'error',
        });
        setTimeout(() => {
          promoMutate({});
        }, 500);
        return res.data || {};
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const { isLoading: isUploadLoading, data: reportsData } = useQuery(
    ['PromotionData', page, active_stores, pageSize],
    uploadPromoData,
    {
      refetchOnWindowFocus: false,
    },
  );
  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    await setUploadData(file);
    uploadPromoData(file);
  };

  const renderReportTable = () => {
    // TODO: to update/add render function for all the columns here for customizing response data to UI needs

    let columns: any = [
      {
        title: <FormattedMessage id="retailer" defaultMessage="Retailer" />,
        field: 'retailer',
      },
      {
        title: <FormattedMessage id="promoId" defaultMessage="Promo ID" />,
        field: 'promo_id_retailer',
      },
      {
        title: (
          <FormattedMessage id="promoFamily" defaultMessage="Promo Family" />
        ),
        field: 'promo_family',
      },
      {
        title: (
          <FormattedMessage id="discountType" defaultMessage="Discount Type" />
        ),
        field: 'discount_type',
      },
      {
        title: <FormattedMessage id="totalSales" defaultMessage="Title" />,
        field: 'title',
        align: 'left',
        render: (rowData: any) => rowData?.title,
      },
      {
        title: <FormattedMessage id="sales" defaultMessage="Discount Value" />,
        field: 'discount_value',
        align: 'left',
        render: (rowData: any) => rowData?.discount_value,
      },
      {
        title: <FormattedMessage id="itemId" defaultMessage="Item ID" />,
        field: 'item_id',
        align: 'left',
        render: (rowData: any) => rowData?.item_id,
      },
      {
        title: (
          <FormattedMessage id="numberOfShifts" defaultMessage="Is Active" />
        ),
        field: 'is_active',
        render: (rowData: any) => (rowData?.is_active ? 'True' : 'False'),
      },
      {
        title: <FormattedMessage id="isSpecial" defaultMessage="Is Special" />,
        field: 'is_special',
        render: (rowData: any) => (rowData?.is_special ? 'True' : 'False'),
      },
      {
        title: (
          <FormattedMessage
            id="requisteDiscoountGroup"
            defaultMessage="Requisite Discount Group"
          />
        ),
        field: 'requisite_discount_group',
        render: (rowData: any) => rowData?.requisite_discount_group,
      },
      {
        title: (
          <FormattedMessage
            id="requisteDiscoountGroupQty"
            defaultMessage="Requisite Discount Group Qty"
          />
        ),
        field: 'requisite_discount_group',
        render: (rowData: any) => rowData?.requisite_discount_group_qty,
      },
      {
        title: <FormattedMessage id="groupName" defaultMessage="Group Name" />,
        field: 'group_name',
        align: 'left',
        render: (rowData: any) => rowData?.group_name,
      },
      {
        title: <FormattedMessage id="returns" defaultMessage="Start Date" />,
        field: 'start_date_time',
        align: 'left',
        render: (rowData: any) => rowData?.start_date_time,
      },
      {
        title: <FormattedMessage id="taxes" defaultMessage="End Date" />,
        field: 'end_date_time',
        align: 'left',
        render: (rowData: any) => rowData?.end_date_time,
      },
    ];
    return (
      <MaterialTable
        title=""
        columns={columns}
        data={get(promoData, 'results', [])}
        icons={tableIcons}
        isLoading={isPromoLoading}
        options={{
          paging: false,
          search: false,
          sorting: false,
          overflowY: 'auto',
          headerStyle: {
            backgroundColor: '#A6C1FF',
            height: 40,
            fontWeight: 400,
            fontSize: 13,
            color: '#222B45',
          },
          padding: 'dense',
          loadingType: 'overlay',
          // detailPanelColumnAlignment: 'right',
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData.isShiftsOpen ? '#222B45' : '#F7F9FC',
            height: 40,
            fontWeight: 700,
            fontSize: 12,
            color: rowData.isShiftsOpen ? '#FFFFFF' : '#222B45',
          }),
          tableLayout: 'auto',
        }}
        style={{ marginLeft: 24, marginRight: 24 }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box>
        <NavigationTopBar
          title={`Promotions`}
          refresh={active_stores.length === 1}
          download={active_stores.length === 1}
          upload={active_stores.length === 1}
          onRefreshClick={() => {
            promoMutate({});
          }}
          onDownloadClick={() => {
            fetchPromoData(true);
          }}
          onUploadClick={() => {
            inputFile.current.click();
          }}
          onCleanUploadClick={e => {
            setCleanUpload(e);
          }}
        />
      </Box>
      <Box component="div" className={classes.bodyContainer}>
        {(isPromoLoading || loader || loading) && (
          <Box className={classes.loader}>
            <CircularProgress color="primary" size={30} />
          </Box>
        )}
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={onChangeFile.bind(this)}
          onClick={event => ((event.target as HTMLInputElement).value = null)}
          style={{ display: 'none' }}
        />
        {active_stores.length === 1 &&
        get(promoData, 'results', []).length >= 1 ? (
          <>
            <Box component="div" className={classes.body}>
              {renderReportTable()}
            </Box>
            <Paper className={classes.footer}>
              <InputLabel htmlFor="age-simple" style={{ marginRight: 8 }}>
                No. of Items
              </InputLabel>
              <Select
                style={{ marginRight: 8 }}
                value={pageSize}
                onChange={(event: any) => {
                  dispatch({
                    type: 'CHANGE_ROWS_PER_PAGE',
                    payload: event.target.value,
                  });
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              <Pagination
                count={Math.ceil(get(promoData, 'count', 10) / pageSize)}
                shape="rounded"
                size="medium"
                color="primary"
                page={page}
                onChange={handlePagination}
              />
            </Paper>
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              {active_stores.length > 1 ? (
                <>
                  <p>
                    Promotions are not available when multiple stores are
                    selected
                  </p>
                  <p>Please select a single store to continue</p>
                </>
              ) : (
                <FormattedMessage
                  id="noReports"
                  defaultMessage="No Reports Available"
                />
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Snackbar
        open={toast.visible}
        autoHideDuration={3000}
        onClose={hideToast}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={hideToast} severity={toast?.severity}>
          {toast?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default memo(Promotions);
