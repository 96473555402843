import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import VerifiedIcon from 'src/assets/svgIcons/VerifiedIcon';
import PickingRequiredIcon from 'src/assets/svgIcons/PickingRequiredIcon';
import ReadyToCollectIcon from 'src/assets/svgIcons/ReadyToCollectIcon';
import PartialRefundedIcon from 'src/assets/svgIcons/PartialRefundedIcon';
import NewOrderIcon from 'src/assets/svgIcons/NewOrderIcon';
import IncorrectIcon from 'src/assets/svgIcons/IncorrectIcon';
import FullyRefundedIcon from 'src/assets/svgIcons/FullyRefundedIcon';
import CancelledRefundedIcon from 'src/assets/svgIcons/CancelledRefundedIcon';
import AttentionIcon from 'src/assets/svgIcons/AttentionIcon';
import { v4 as uuidv4 } from 'uuid';

export const computeStores = (storesData: any) => {
  let modifyStoresData = storesData.map((item: any) => {
    if (!item.store_id && item.retailer.split(' ').includes('All')) {
      return {
        ...item,
        store_id:
          'all_stores_' +
          item.store_type.replace('StoreType', ' ').toLowerCase(),
      };
    } else return item;
  });
  let all_stores_check = storesData.some(
    (item: any) => item.retailer === 'All stores',
  );
  if (!all_stores_check) {
    modifyStoresData.push({
      store_id: 'all_stores',
      retailer: 'All Stores',
      currency: '',
      store_type: '',
      mixpanel_token: '',
    });
  }
  return modifyStoresData;
};

export const orderStatus = (
  status: string = 'success',
  isPartialRefund: boolean,
  miniMode: boolean = false,
) => {
  const params: any = {
    completed: {
      color: isPartialRefund ? 'error' : 'warning',
      text: miniMode ? (
        isPartialRefund ? (
          <FormattedMessage id="partialRefunded" />
        ) : (
          <FormattedMessage id="attention" />
        )
      ) : isPartialRefund ? (
        <FormattedMessage id="partialRefunded" />
      ) : (
        <FormattedMessage id="verificationReq" />
      ),
      iconName: isPartialRefund ? (
        <PartialRefundedIcon fill="error" />
      ) : (
        <AttentionIcon />
      ),
    },
    verified: {
      color: isPartialRefund ? 'error' : 'success',
      text: miniMode ? (
        isPartialRefund ? (
          <FormattedMessage id="refunded" />
        ) : (
          <FormattedMessage id="verified" />
        )
      ) : isPartialRefund ? (
        <FormattedMessage id="partialRefunded" />
      ) : (
        <FormattedMessage id="orderVerified" />
      ),
      iconName: isPartialRefund ? (
        <PartialRefundedIcon fill="error" />
      ) : (
        <VerifiedIcon fill="success" />
      ),
    },
    verification_failed: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="incorrect" />
      ) : (
        <FormattedMessage id="verifyFail" />
      ),
      iconName: <IncorrectIcon fill="error" />,
    },
    incorrect: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="incorrect" />
      ) : (
        <FormattedMessage id="incorrectOrder" />
      ),
      iconName: <IncorrectIcon fill="error" />,
    },
    refunded: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="refunded" />
      ) : isPartialRefund ? (
        <FormattedMessage id="partialRefunded" />
      ) : (
        <FormattedMessage id="orderRefunded" />
      ),
      iconName: isPartialRefund ? (
        <PartialRefundedIcon fill="error" />
      ) : (
        <FullyRefundedIcon fill="error" />
      ),
    },
    created: {
      color: 'success',
      text: miniMode ? (
        <FormattedMessage id="new" />
      ) : (
        <FormattedMessage id="newOrder" />
      ),
      iconName: <NewOrderIcon fill="success" />,
    },
    collected: {
      color: 'info',
      text: miniMode ? (
        <FormattedMessage id="collected" />
      ) : (
        <FormattedMessage id="collected" />
      ),
      iconName: <VerifiedIcon fill="#FFFFFF" />,
      appearance: 'contained',
    },
    collected_refunded: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="refunded" />
      ) : (
        <FormattedMessage id="collectedRefund" />
      ),
      iconName: <FullyRefundedIcon fill="error" />,
    },
    not_collected: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="cancelled" />
      ) : (
        <FormattedMessage id="cancelledRefund" />
      ),
      iconName: <CancelledRefundedIcon fill="error" />,
    },
    accepted: {
      color: 'warning',
      text: miniMode ? (
        <FormattedMessage id="pickNow" />
      ) : (
        <FormattedMessage id="pickingRequired" />
      ),
      iconName: <PickingRequiredIcon fill="warning" />,
    },
    ready_to_collect: {
      color: 'info',
      text: miniMode ? (
        <FormattedMessage id="ready" />
      ) : (
        <FormattedMessage id="readyToCollect" />
      ),
      iconName: <ReadyToCollectIcon fill="info" />,
    },
    rejected_store: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="cancelled" />
      ) : (
        <FormattedMessage id="cancelledRefund" />
      ),
      iconName: <CancelledRefundedIcon fill="error" />,
    },
    rejected_customer: {
      color: 'error',
      text: miniMode ? (
        <FormattedMessage id="cancelled" />
      ) : (
        <FormattedMessage id="cancelledRefund" />
      ),
      iconName: <CancelledRefundedIcon fill="error" />,
    },
    verified_collected: {
      color: 'success',
      text: miniMode ? (
        <FormattedMessage id="collected" />
      ) : (
        <FormattedMessage id="verifiedCollection" />
      ),
      iconName: <VerifiedIcon fill="success" />,
    },
    serial_number_required: {
      color: 'warning',
      text: miniMode ? (
        <FormattedMessage id="snRequired" />
      ) : (
        <FormattedMessage id="serialNumberRequired" />
      ),
      iconName: <AttentionIcon />,
      appearance: 'contained',
    },
  };

  let iconName = get(params[status], 'iconName', '');
  let statusValue = get(params[status], 'text', '');
  let statusColor = get(params[status], 'color', '');
  let buttonAppearance = get(params[status], 'appearance', 'outlined');

  return {
    icon: iconName,
    status: statusValue,
    color: statusColor,
    variant: buttonAppearance,
  };
};

export const getOrderCount = (orderData: any) => {
  try {
    let refunded_items = get(orderData, 'refunded_items', []),
      non_refunded_items = get(orderData, 'non_refunded_items', []),
      refund_processing_items = get(orderData, 'refund_processing_items', []);
    if (orderData?.total_number_of_items)
      return orderData?.total_number_of_items;
    return [
      ...refunded_items,
      ...non_refunded_items,
      ...refund_processing_items,
    ].reduce((acc, val) => (acc = acc + val.item_quantity), 0);
  } catch (e) {
    console.log({ e });

    return 0;
  }
};

export const getCurrency = (currency: string) => {
  switch (currency?.toLowerCase()) {
    case 'usd':
      return '$';
    case 'euro':
    case 'eur':
    case '€':
      return '€';
    case 'gbp':
      return '£';
    case 'inr':
      return '₹';
    case 'dkk':
    case 'se':
    case 'sek':
    case 'nok':
      return 'kr.';
    case 'chf':
      return 'CHF';
    case 'mvr':
      return 'MVR';
    case 'aed':
      return 'AED';
    case 'sar':
      return 'SAR';
    case 'aud':
      return 'A$';
    default:
      return currency?.toUpperCase();
  }
};

export const currencyFormat = (currency: string) => {
  switch (currency?.toLowerCase()) {
    case 'euro':
      return 'EUR';
    default:
      return currency.toUpperCase();
  }
};

export const textFormat = (text: string) => {
  return text.replaceAll('_', ' ');
};

export const validateUPC = (barcode: any) => {
  let evenSum = 0;
  let oddSum = 0;
  let checkDigit = parseInt(barcode.charAt(11));
  for (let i = 0; i < 11; i++) {
    if (i % 2 == 0) {
      oddSum = oddSum + parseInt(barcode.charAt(i));
    } else {
      evenSum = evenSum + parseInt(barcode.charAt(i));
    }
  }
  let sum = oddSum * 3 + evenSum;
  let multiple = Math.ceil(sum / 10) * 10;
  if (multiple - sum == checkDigit) {
    return true;
  } else {
    return false;
  }
};

export const validateEAN13 = (barcode: any) => {
  let evenSum = 0;
  let oddSum = 0;
  let checkDigit = parseInt(barcode.charAt(12));
  for (let i = 0; i < 12; i++) {
    if (i % 2 == 0) {
      oddSum = oddSum + parseInt(barcode.charAt(i));
    } else {
      evenSum = evenSum + parseInt(barcode.charAt(i));
    }
  }
  let sum = evenSum * 3 + oddSum;
  let multiple = Math.ceil(sum / 10) * 10;
  if (multiple - sum == checkDigit) {
    return true;
  } else {
    return false;
  }
};

export const validateITF14 = (barcode: any) => {
  let evenSum = 0;
  let oddSum = 0;
  let checkDigit = parseInt(barcode.charAt(13));
  for (let i = 0; i < 13; i++) {
    if (i % 2 == 0) {
      oddSum = oddSum + parseInt(barcode.charAt(i));
    } else {
      evenSum = evenSum + parseInt(barcode.charAt(i));
    }
  }
  let sum = evenSum * 3 + oddSum;
  let multiple = Math.ceil(sum / 10) * 10;
  if (multiple - sum == checkDigit) {
    return true;
  } else {
    return false;
  }
};

export const goToOrderDetails = ({ order_id, type, from, history }: any) => {
  history.push({
    pathname: `/orderDetails/${order_id}`,
    state: { order_id, type, from },
  });
};

export const constructUIData = (
  promo_version: any,
  orderData: any,
  promoData: any,
  serial_number_required = false,
) => {
  try {
    switch (promo_version) {
      case 1:
        let refunded_items = get(orderData, 'refunded_items', ''),
          non_refunded_items = get(orderData, 'non_refunded_items', ''),
          refund_processing_items = get(
            orderData,
            'refund_processing_items',
            '',
          );
        let basket = {
          id: uuidv4(),
          items_returned: {
            promos: [],
            no_promos:
              refunded_items.length > 0
                ? refunded_items.map((item: any) => {
                  return {
                    group_title: '',
                    group_items: [item],
                    group_items_qty_editable: true,
                  };
                })
                : [],
          },
          items_available: {
            promos: [],
            no_promos:
              non_refunded_items.length > 0
                ? non_refunded_items.map((item: any) => {
                  return {
                    group_title: '',
                    group_items: [item],
                    group_items_qty_editable: true,
                  };
                })
                : [],
          },
          items_processing: {
            promos: [],
            no_promos:
              refund_processing_items.length > 0
                ? refund_processing_items.map((item: any) => {
                  return {
                    group_title: '',
                    group_items: [item],
                    group_items_qty_editable: true,
                  };
                })
                : [],
          },
        };
        return {
          basket,
          total_order_amount: get(orderData, 'total_price', ''),
          total_refunded_amount: '0.00',
          last_refunded_order_id: null,
          last_refunded_amount: '0.00',
          discounted_price: get(orderData, 'discounted_price', ''),
        };
      case 2:
        if (serial_number_required && promoData.basket) {
          promoData.basket.items_available.promos =
            promoData.basket.items_available.promos.filter(filterItems);
          promoData.basket.items_available.no_promos =
            promoData.basket.items_available.no_promos.filter(filterItems);
          promoData.basket.items_returned.promos =
            promoData.basket.items_returned.promos.filter(filterItems);
          promoData.basket.items_returned.no_promos =
            promoData.basket.items_returned.no_promos.filter(filterItems);
        }
        return promoData;
      default:
        return {};
    }
  } catch (e) {
    console.log({ e });
    return {};
  }
};

function filterItems(item: any) {
  let actualItem = Array.isArray(item.group_items[0])
    ? item.group_items[0][0]
    : item.group_items[0];
  return actualItem.serial_number_required !== true;
}

export const getSelectedStoreIds = (
  stores: any,
  selectedRetailers: any,
  selectedRegions: any,
  selectedStores: any,
) => {
  try {
    let filterStoresByRetailer = selectedRetailers.length
      ? stores.filter((store: any) => {
        return selectedRetailers.some((item: any) => {
          if (
            store?.store_type
              ?.toLowerCase()
              .includes(item.value?.toLowerCase())
          )
            return true;
          else return false;
        });
      })
      : stores;
    let filterStoresByRegion = selectedRegions.length
      ? filterStoresByRetailer.filter((store: any) => {
        return selectedRegions.some((item: any) => {
          if (
            store?.region?.toLowerCase().includes(item.value?.toLowerCase())
          )
            return true;
          else return false;
        });
      })
      : filterStoresByRetailer;
    if (
      selectedStores.length === filterStoresByRegion.length ||
      selectedStores.length === stores.length
    ) {
      return [];
    } else return selectedStores.map((store: any) => store.store_id);
  } catch (e) {
    return [];
  }
};

export const getSelectedStoreAppClipIds = (
  stores: any,
  selectedRetailers: any,
  selectedRegions: any,
  selectedStores: any,
  include_demo_stores: boolean,
  include_inactive_stores: boolean,
) => {
  try {
    let filterDemoAndInactiveStores = getRequiredStores(
      stores,
      include_demo_stores,
      include_inactive_stores,
    );
    let filterStoresByRetailer = selectedRetailers.length
      ? filterDemoAndInactiveStores.filter((store: any) => {
        return selectedRetailers.some((item: any) => {
          if (
            store?.store_type
              ?.toLowerCase()
              .includes(item.value?.toLowerCase())
          )
            return true;
          else return false;
        });
      })
      : filterDemoAndInactiveStores;
    let filterStoresByRegion = selectedRegions.length
      ? filterStoresByRetailer.filter((store: any) => {
        return selectedRegions.some((item: any) => {
          if (
            store?.region?.toLowerCase().includes(item.value?.toLowerCase())
          )
            return true;
          else return false;
        });
      })
      : filterStoresByRetailer;
    if (
      selectedStores.length === filterStoresByRegion.length ||
      selectedStores.length === stores.length
    ) {
      return [];
    } else return selectedStores.map((store: any) => store.app_clip_id);
  } catch (e) {
    return [];
  }
};

export const getDate = (date: any) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  return [year, month, day].join('-');
};

export const getDateDifference = (date1: any, date2: any) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1 > 60;
};

export const verificationReasons = (reason: any, tags: any, intl: any) => {
  if (reason === 'g' && tags != null && tags.length > 0) {
    return tags[0];
  }

  switch (reason) {
    case 'g':
      if (tags != null && tags.length > 0) {
        return tags.join(' ');
      } else {
        return intl.formatMessage({ id: 'mandatoryCheck' });
      }
    case 'a':
      return intl.formatMessage({ id: 'checkCustomerId' });
    case 'w':
      return intl.formatMessage({ id: 'checkWeightedItems' });
    case 'v':
      return intl.formatMessage({ id: 'checkValueOver20' });
    case 'r':
      return intl.formatMessage({ id: 'randomCheck' });
    default:
      return intl.formatMessage({ id: 'randomCheck' });
  }
};

export const getRequiredStores = (
  stores: any,
  includeDemoStores: boolean,
  includeInactiveStores: boolean,
) => {
  return includeDemoStores && includeInactiveStores
    ? stores
    : includeDemoStores && !includeInactiveStores
      ? stores.filter((item: any) => {
        return item.store_id && (item.demo || item.active);
      })
      : !includeDemoStores && includeInactiveStores
        ? stores.filter((item: any) => {
          return item.store_id && !item.demo;
        })
        : stores.filter((item: any) => {
          return item.store_id && !item.demo && item.active;
        });
};
// roundingOff ToDeal With Unexpected ToFixed Issue i.e 1.335.toFixed(2) => 1.33 whereas it should be 1.34 like if we use 8.335 or greater
export const updateDecimals = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, e => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};
