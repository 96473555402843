import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography } from '@material-ui/core';
import { Header } from '../../components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const EmailReport = () => {
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const isCartersOnly = () => {
    let isOnlyCarters = true;
    active_stores?.filter((store: any) => {
      if (store?.store_type !== 'CartersStoreType') {
        isOnlyCarters = false;
      }
    });
    return isOnlyCarters;
  };
  const showEmailReport = isCartersOnly();
  return (
    <Box>
      <Header
        title={
          <FormattedMessage
            id="revenueReport"
            defaultMessage="Email Report"
          />
        }
        subtitle={
          <FormattedMessage
            id="revenueReportOf"
            defaultMessage="Email report of"
          />
        }
        rightAccessory={() => {
          return <></>;
        }}
      />

      <Box component="div">
        {showEmailReport ? (
          <div>
            <iframe
              width="100%"
              height="800px"
              src="https://lookerstudio.google.com/embed/reporting/b501ab65-2484-4b58-a42b-96e35d951f9f/page/vcHNE"
              sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
            ></iframe>
          </div>
        ) : (
          <div>
            <FormattedMessage
              id="noReports"
              defaultMessage="No Reports Available"
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default EmailReport;
