import request from './request';

const constructUrl = (requestUrl: string, params: any = {}) => {
  let requestParams: Array<string> = [];
  let queryParams: any = {};
  let urlArr = requestUrl.split(':');
  let staticPath = urlArr[0];
  let dynamicPath = urlArr
    .slice(1)
    .map(path => {
      let arr = path.split('/');
      requestParams.push(arr[0]);
      return Object.keys(params).includes(arr[0])
        ? arr.length > 1
          ? params[arr[0]] + '/'
          : params[arr[0]]
        : '';
    })
    .join('');
  Object.keys(params).map(a => {
    if (!requestParams.includes(a)) queryParams[a] = params[a];
  });
  return {
    requestUrl: staticPath.concat(dynamicPath),
    params: queryParams,
  };
};

export default (base: string) => {
  const create = (input: string, data: any) => {
    let { requestUrl, params } = constructUrl(base, input);
    return request({ url: requestUrl, method: 'POST', data, params });
  };

  const file = (input: string, data: any) => {
    let { requestUrl, params } = constructUrl(base, input);
    const formData = new FormData();
    for (let param in data) {
      formData.append(param, data[param]);
    }
    return request({ url: requestUrl, method: 'POST', data, params });
  };

  const get = (input: string) => {
    let { requestUrl, params } = constructUrl(base, input);
    return request({ url: requestUrl, method: 'GET', params });
  };

  const query = (input: string) => {
    let { requestUrl, params } = constructUrl(base, input);
    return request({ url: requestUrl, method: 'GET', params });
  };

  const remove = (input: string) => {
    let { requestUrl, params } = constructUrl(base, input);
    return request({ url: requestUrl, method: 'DELETE', params });
  };

  const update = (input: string, data: any) => {
    let { requestUrl, params } = constructUrl(base, input);
    return request({ url: requestUrl, method: 'PUT', data, params });
  };

  const resource = {
    create,
    file,
    get,
    query,
    remove,
    update,
  };
  return Object.assign(resource);
};
