import { get } from 'lodash';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [cookies] = useCookies();
  const { active_stores } = useSelector((state: any) => state.store);

  const routeToScreens = (props: any) => {
    const path = props?.location?.pathname;
    let clickAndCollectStore = active_stores.find((store: any) =>
      get(store, 'features_applicable.click_and_collect', false),
    );
    const isClickAndCollect = get(
      clickAndCollectStore,
      'features_applicable.click_and_collect',
      false,
    );
    let hasPromoEnabled = active_stores.some((store: any) =>
      get(store, 'features_applicable.promotion', false),
    );
    let cashDeskStore = active_stores.find((store: any) =>
      get(store, 'features_applicable.cashier_kiosk', false),
    );
    const isCashDesk = get(
      cashDeskStore,
      'features_applicable.cashier_kiosk',
      false,
    );
    const isCartersOnly = () => {
      let isOnlyCarters = true;
      active_stores?.filter((store: any) => {
        if (store?.store_type !== 'CartersStoreType') {
          isOnlyCarters = false;
        }
      });
      return isOnlyCarters;
    };
    const showEmailReport = isCartersOnly();
    const checkMLSEStoreType = () => {
      let hasMLSEOnlyStore = true;
      active_stores?.filter((store: any) => {
        if (store?.store_type !== 'MLSEStoreType') {
          hasMLSEOnlyStore = false;
        }
      });
      return hasMLSEOnlyStore;
    };
    const showRevenueReport =
      (active_stores.length === 1 &&
        active_stores[0]['store_type'] === 'CartersStoreType') ||
      checkMLSEStoreType()
        ? true
        : false;
    const itemReport = checkMLSEStoreType() ? true : false;

    if (path === '/dashboard/click_and_collect' && isClickAndCollect) {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    } else if (path === '/dashboard/click_and_collect' && !isClickAndCollect) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/cashDesk' && isCashDesk) {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    } else if (path === '/dashboard/cashDesk' && !isCashDesk) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/revenue' && !showRevenueReport) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/emailReport' && !showEmailReport) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/promos' && !hasPromoEnabled) {
      return <Redirect to="/" />;
    } else if (path === '/dashboard/itemReport' && !itemReport) {
      return <Redirect to="/" />;
    } else {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    }
  };
  return <Route {...rest} render={(props: any) => routeToScreens(props)} />;
};

export default PrivateRoute;
