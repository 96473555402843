import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  IconButton,
  Typography,
  Button,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '../../assets/refresh.svg';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import RefreshSvgComponentIcon from 'src/assets/svgIcons/RefreshSvgCompontIcon';
import DownloadSvgCompontIcon from 'src/assets/svgIcons/DownloadSvgCompontIcon';
import UploadSvgCompontIcon from 'src/assets/svgIcons/UploadSvgCompontIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '79px',
    backgroundColor: theme.palette.color_white.main,
    borderBottom: `1px solid ${theme.palette.color_light_grey.main}`,
    borderRadius: 0,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  backArrow: {
    color: theme.palette.color_black.main,
  },

  title: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 18,
  },

  titleMargin: {
    marginLeft: 20,
  },

  subtitle: {
    fontSize: 12,
    color: theme.palette.color_text_grey.main,
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 10,
  },
  buttonColor: {
    background: '#ffaa00',
    '&:hover': {
      backgroundColor: (props: any) => `#c68400`,
    },
  },
  buttonPrint: {
    marginRight: 20,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
  },
  buttonDownload: {
    width: 'auto',
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: '#fff',
    '&:hover': {
      backgroundColor: (props: any) => '#3366ffe6',
      border: (props: any) => `1px solid #3366ffe6 !important`,
    },
  },
  buttonDownloadRipple: {
    color: '#fff',
  },
  buttonUpload: {
    width: 'auto',
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: '#fff',
    '&:hover': {
      backgroundColor: (props: any) => '#3366ffe6',
      border: (props: any) => `1px solid #3366ffe6 !important`,
    },
  },
  buttonUploadRipple: {},
  buttonRefresh: {
    width: (props: any) => props.width,
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },
  buttonRefreshRipple: {
    color: '#fff',
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
  },
  icon: { width: 20, height: 20 },
}));

const NavigationTopBar = ({
  title = null,
  titlekey = null,
  defaultValue = null,
  arrowPresent = true,
  subtitle = null,
  refresh = false,
  download = false,
  upload = false,
  onCleanUploadClick = null,
  printReceipt = false,
  onRefreshClick = null,
  onDownloadClick = null,
  onUploadClick = null,
  onPrintReceiptClick = null,
  onEmailReceiptClick = null,
  rightComponent = () => <></>,
}: any) => {
  const theme: any = useTheme();
  const width = window.innerWidth < theme.breakpoints.values['sm'] ? 40 : 100;
  const classes = useStyles({ b1bg: 'info', width });
  const history = useHistory();
  const [checked, setChecked] = useState<boolean>(false);
  let newClass = !arrowPresent
    ? clsx(classes.textContainer, classes.titleMargin)
    : classes.textContainer;

  useEffect(() => {
    console.log('uploadData', checked);
  }, [checked]);
  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        {arrowPresent && (
          <IconButton onClick={history.goBack}>
            <ArrowBackIcon className={classes.backArrow} />
          </IconButton>
        )}
        <Box className={newClass}>
          <Typography className={classes.title}>
            {titlekey && defaultValue && (
              <FormattedMessage id={titlekey} defaultMessage={defaultValue} />
            )}
            {title ? title : null}
          </Typography>
          {subtitle && (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          )}
        </Box>

        {printReceipt && (
          <Button
            className={`${classes.buttonPrint} ${classes.buttonColor}`}
            // className={classes.buttonPrint}
            variant="contained"
            onClick={onEmailReceiptClick}
            color={'secondary'}
          >
            <FormattedMessage
              id="emailReceipt"
              defaultMessage="EMAIL RECEIPT"
            />
          </Button>
        )}
        {printReceipt && (
          <Button
            className={classes.buttonPrint}
            variant="contained"
            onClick={onPrintReceiptClick}
            color={'secondary'}
          >
            <FormattedMessage
              id="printReceipt"
              defaultMessage="PRINT RECEIPT"
            />
          </Button>
        )}
        {download && width > 40 && (
          <Button
            className={classes.buttonDownload}
            variant="outlined"
            onClick={onDownloadClick}
            startIcon={<DownloadSvgCompontIcon />}
            TouchRippleProps={{
              classes: { root: classes.buttonDownloadRipple },
            }}
          >
            <FormattedMessage id="refresh" defaultMessage="Download" />
          </Button>
        )}
        {download && width === 40 && (
          <IconButton
            onClick={onDownloadClick}
            className={classes.buttonDownload}
            TouchRippleProps={{
              classes: { root: classes.buttonDownloadRipple },
            }}
          >
            <img className={classes.icon} src={RefreshIcon} alt="Download" />
          </IconButton>
        )}
        {upload && width > 40 && (
          <>
            <Box
              style={{
                marginRight: 20,
                paddingLeft: 20,
                borderLeft: `1px solid #dadada`,
              }}
            >
              <Checkbox
                checked={checked}
                color="primary"
                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                style={{ paddingLeft: 0 }}
                onClick={onCleanUploadClick(checked)}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              Clean Upload
            </Box>
            <Button
              className={classes.buttonUpload}
              variant="outlined"
              onClick={onUploadClick}
              startIcon={<UploadSvgCompontIcon />}
              TouchRippleProps={{
                classes: { root: classes.buttonUploadRipple },
              }}
            >
              <FormattedMessage id="refresh" defaultMessage="Upload" />
            </Button>
          </>
        )}
        {upload && width === 40 && (
          <IconButton
            onClick={onUploadClick}
            className={classes.buttonRefresh}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
            <img className={classes.icon} src={RefreshIcon} alt="Upload" />
          </IconButton>
        )}
        {refresh && width > 40 && (
          <Button
            className={classes.buttonRefresh}
            variant="outlined"
            onClick={onRefreshClick}
            startIcon={<RefreshSvgComponentIcon />}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
            <FormattedMessage id="refresh" defaultMessage="REFRESH" />
          </Button>
        )}
        {refresh && width === 40 && (
          <IconButton
            onClick={onRefreshClick}
            className={classes.buttonRefresh}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
            <img className={classes.icon} src={RefreshIcon} alt="refresh" />
          </IconButton>
        )}
        {rightComponent()}
      </Box>
    </Paper>
  );
};

export default NavigationTopBar;
