import { Paper, Typography, Box } from '@material-ui/core';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { updateDecimals } from '../../../../utils';

const Pie = ({ data: chartData, title, total, size }: any) => {
  let values = chartData.map((item: any) => item?.value);
  let backgroundColors = chartData.map((item: any) => item?.color);
  let labels = chartData.map((item: any) => item.title);

  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Paper
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 16,
        minHeight: 520,
      }}
    >
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 'bold',
          alignSelf: 'flex-start',
          marginBottom: 16,
        }}
      >
        {title}
      </Typography>
      <>
        {values && values.length && total != 0 ? (
          <Box
            style={{
              position: 'relative',
              alignSelf: 'center',
              width: size === 'large' ? '90%' : '67%',
              alignItems: 'center',
            }}
          >
            <Doughnut
              data={data}
              options={{
                plugins: {
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                },
              }}
            />
          </Box>
        ) : (
          <Box
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle1">No data available</Typography>
          </Box>
        )}
      </>
      <Box
        style={{
          padding: 16,
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          marginTop: 16,
        }}
      >
        {chartData.map((item: any) => {
          let percent = updateDecimals((item.value / total) * 100)?.toFixed(2);
          let checkNaN = isNaN(Number(percent)) ? '' : percent;
          let percentValue = checkNaN ? ` - ( ${checkNaN} % )` : '';
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 4,
              }}
            >
              <Box
                style={{
                  height: 12,
                  width: 30,
                  backgroundColor: item.color,
                  marginRight: 8,
                  textAlign: 'left',
                }}
              ></Box>
              <Typography>
                {item.title} - {item.value}
                {percentValue}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Paper>
  );
};

export const PieChart = React.memo(Pie);
