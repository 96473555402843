import { SvgIcon } from '@material-ui/core';
import React from 'react';

const UploadSvgCompontIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.45638 3.8656H16.123C16.5814 3.8656 16.9564 4.2406 16.9564 4.69893V6.3656C16.9564 6.82393 16.5814 7.19893 16.123 7.19893C15.6647 7.19893 15.2897 6.82393 15.2897 6.3656V5.53227H5.28971V6.3656C5.28971 6.82393 4.91471 7.19893 4.45638 7.19893C3.99805 7.19893 3.62305 6.82393 3.62305 6.3656V4.69893C3.62305 4.2406 3.99805 3.8656 4.45638 3.8656ZM6.95721 12.1989C6.70305 12.1989 6.45388 12.0839 6.28971 11.8656C6.01305 11.4973 6.08805 10.9748 6.45638 10.6989L9.78971 8.19893C10.078 7.98227 10.4747 7.97643 10.7689 8.18393L14.1022 10.5289C14.4789 10.7939 14.5697 11.3139 14.3047 11.6898C14.0397 12.0656 13.5205 12.1573 13.1439 11.8923L11.1207 10.469C11.1223 10.4899 11.123 10.511 11.123 10.5323V17.1989C11.123 17.6589 10.7505 18.0323 10.2897 18.0323C9.82888 18.0323 9.45638 17.6589 9.45638 17.1989V10.5324L7.45638 12.0323C7.30638 12.1448 7.13138 12.1989 6.95721 12.1989Z"
          fill="white"
        />
        <mask
          id="mask0_3690_9502"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="14"
          height="16"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.45638 3.8656H16.123C16.5814 3.8656 16.9564 4.2406 16.9564 4.69893V6.3656C16.9564 6.82393 16.5814 7.19893 16.123 7.19893C15.6647 7.19893 15.2897 6.82393 15.2897 6.3656V5.53227H5.28971V6.3656C5.28971 6.82393 4.91471 7.19893 4.45638 7.19893C3.99805 7.19893 3.62305 6.82393 3.62305 6.3656V4.69893C3.62305 4.2406 3.99805 3.8656 4.45638 3.8656ZM6.95721 12.1989C6.70305 12.1989 6.45388 12.0839 6.28971 11.8656C6.01305 11.4973 6.08805 10.9748 6.45638 10.6989L9.78971 8.19893C10.078 7.98227 10.4747 7.97643 10.7689 8.18393L14.1022 10.5289C14.4789 10.7939 14.5697 11.3139 14.3047 11.6898C14.0397 12.0656 13.5205 12.1573 13.1439 11.8923L11.1207 10.469C11.1223 10.4899 11.123 10.511 11.123 10.5323V17.1989C11.123 17.6589 10.7505 18.0323 10.2897 18.0323C9.82888 18.0323 9.45638 17.6589 9.45638 17.1989V10.5324L7.45638 12.0323C7.30638 12.1448 7.13138 12.1989 6.95721 12.1989Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3690_9502)"></g>
      </svg>
    </SvgIcon>
  );
};

export default UploadSvgCompontIcon;
